main.section {
  padding-top: 0;
}

main-wrapper {
  overflow: hidden;
}

layout-container > layout-columns > .column {
  @include until($desktop) {
    padding-left: 0;
    padding-right: 0;
  }
}

.breadcrumbs li {
  display: inline-block;
  &:nth-child(1n + 2)::before {
    content: "::";
    opacity: 0.75;
    vertical-align: 1px;
  }
}

.hero-body {
  @include until($desktop) {
    padding-bottom: 0;
  }
}

footer.footer {
  margin-top: 8rem;

  .logogram {
    width: 175px;
    margin-left: 3rem;
  }
}

.box > layout-columns {
  @include until($tablet) {
    display: block;
  }
}

layout-box[featured] {
  h3 {
    font-family: $title-family;
    margin-bottom: var(--spacing-3);
    color: inherit;
  }
}

layout-box[options] {
  background: linear-gradient(
    to bottom right,
    $black,
    $darkest-blue,
    darken($dark-blue, 5%) 68%,
    $secondary
  );

  hr {
    background-color: darken($secondary, 25%);
  }

  ul {
    font-weight: bold;
    line-height: 2;
  }
}

.touch-negative-4 {
  @include until($desktop) {
    margin-top: -1.5rem;
  }
}
