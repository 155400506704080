[hidden] {
  display: none;
}

:not(.title, .tag, .has-primary-links) > a:not(.button) {
  text-decoration: underline;
  text-underline-offset: .15ch;
  text-decoration-color: rgb(20, 65, 120);
  font-weight: 600;

  &:hover {
    text-decoration-color: var(--color-secondary);
  }
}

.title > :where(a), layout-columns :where(.has-primary-links > a) {
  color: var(--color-primary);
}

.title.is-1 {
  font-size: var(--title-size);
}

.content {
  pre {
    border: $code-border;
    background: $code-background;
    margin-bottom: 2em;
  }
  p,
  dl,
  ol,
  ul,
  blockquote,
  table {
    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }
  p > code, li > code {
    word-wrap: break-word;
  }
}

.e-content.column {
  @include desktop {
    margin-right: 1.5rem;
  }
}
layout-sidebar.column {
  @include desktop {
    padding-right: 1.5rem;
  }
  @include fullhd {
    padding-right: 0;
  }

  sl-icon.big {
    font-size: 1.3em;
  }
}

.icon-arrows-right {
  vertical-align: -0.12em;
}

/* Banks/Presets */

summary-metadata {
  line-height: 1.2;
  margin: 0.5em 0;

  .br-wrapper {
    display: inline-block;
    vertical-align: -1px;
    margin-left: 1px;
  }

  .tag {
    margin-bottom: var(--spacing-1);
  }
}

sl-rating {
  --symbol-size: 1rem;
}

presets-list {
  font-size: 120%;

  @include desktop {
    font-size: 150%;
  }

  nav {
    @include until($desktop) {
      overflow: auto;
      max-height: 240px;
    }
  }

  li {
    display: flex;
    gap: var(--spacing-4);
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--callout-border);
    padding: var(--spacing-2) 0;

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  details {
    font-size: 1rem;

    @include tablet {
      min-width: 300px;
    }
  }
}


figure.image > pg-soundclips {
  position: relative;
  top: -4rem;
  height: 0;
}

pg-soundclips {
  pg-soundclip {
    display: inline-block;
    margin-right: 0.8em;
  }
}

@media (max-width: 575px) {
  bank-comment {
    --avatar-size: 55px;
    --body-indent: 80px;
    --author-gap: 18px;
  }
}

img.plugin-image {
  border: var(--callout-border);
  border-radius: var(--box-radius);
}
