nav.navbar {
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.7);
  background-color: #04254eea;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);

  button[data-action="topbar#openNotifications"] {
    padding-left: 1px;
    padding-right: 1px;
  }

  @include desktop {
    form[action="/search"] {
      max-width: 225px;
    }
  }

  @include fullhd {
    form[action="/search"] {
      max-width: none;
    }
  }
}

.navbar-brand .logo {
  img {
    margin-top: -4px;
    width: 200px;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.35));
    margin-left: 0.3rem;
    margin-right: 1rem;
  }
}

a.navbar-item:not(.button) {
  font-weight: 700;
}

nav-menu {
  @include mobile {
    border-bottom: 3px solid var(--color-darker-blue);
  }
}

.has-dropdown {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

notifications-wrapper > .box {
  display: none;
  position: fixed;
  top: 5.1rem;
  right: 0;
  z-index: 31;
  min-width: 300px;
  max-width: 450px;
  max-height: 72vh;
  overflow: auto;
  border-top-right-radius: 0;
}

#notifications-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
}

notification-dot {
  position: absolute;
  display: inline-block;
  height: 8px;
  width: 8px;
  background: var(--color-primary);
  top: 6px;
  right: -5px;
  border-radius: 100%;
}
