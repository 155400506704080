notification-item {
  display: block;
  position: relative;
  margin-bottom: 1rem;

  padding-bottom: 1rem;
  border-bottom: 1px solid var(--color-accent);

  & > p:first-child {
    margin-bottom: 1em !important;
  }
}

li:last-of-type > notification-item {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
