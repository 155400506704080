a:hover {
  text-decoration-color: var(--color-primary);
}

button.is-unstyled {
  all: unset;
  color: var(--color-primary);
  cursor: pointer;

  &:hover {
    color: var(--color-primary-hover);
  }

  & > sl-icon {
    vertical-align: -.125em;
  }
}

.button {
  font-weight: 600;
  white-space: normal;
  word-break: break-word;
}

.button > sl-icon {
  text-shadow: 0px 0px 2px currentColor;
  font-size: 115%;
  height: 1.3em;
  width: 1.3em;
  padding-inline: .2em;

  &:first-child:not(:last-child) {
    margin-left: calc(-0.4em - 1px);
    margin-right: 0.4em;
  }
}

:is(pg-button, button) {
  & [data-when-enabled] {
    display: initial;
  }
  & [data-when-disabled] {
    display: none;
  }
}

:is(pg-button[disabled], button[disabled]) {
  & [data-when-enabled] {
    display: none;
  }
  & [data-when-disabled] {
    display: initial;
  }
}

input.big-shadow {
  box-shadow: 15px 15px 20px rgba(255, 255, 255, 0.03),
    0px 10px 10px rgba(255, 255, 255, 0.05),
    -15px -15px 20px rgba(0, 0, 0, 0.15), 0px -10px 10px rgba(0, 0, 0, 0.1),
    3px 3px 4px rgba(255, 255, 255, 0.05), -3px -3px 4px rgba(0, 0, 0, 0.1);
  background: var(--color-darker-blue);
  color: white;
}
/* alternate inset shadow: inset 3px 3px 10px rgba(0,0,0,0.2), inset -3px -3px 10px rgba(255,255,255,0.1) */

.input,
.textarea,
.select select {
  font-weight: 600;
}

.field.has-addons {
  .control:last-of-type {
    z-index: 4;

    pg-button::part(button) {
      height: 2.5em;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

label.filedrop {
  display: block;
  width: 600px;
  max-width: 100%;
  padding: 3.5em;
  text-align: center;
  border: dashed 2px $primary;
  font-size: inherit;
  font-weight: normal;
  color: white;
  margin: 0;

  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
}

details > summary {
  font-weight: bold;
  margin-block-end: var(--spacing-4);
  color: var(--color-link);
  cursor: pointer;
}
