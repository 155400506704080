article-author,
article-content, /* .content */
form-field, /* .field */
form-control, /* .control */
img-container,
layout-container, /* .container */
layout-pagination,
layout-notice,
layout-spacer,
main-wrapper,
main-content, /* .content */
pg-soundclips,
summary-metadata,
bulk-uploader {
  display: block;
}

layout-pagination {
  text-align: center;
  margin-block: var(--spacing-6);
}

layout-pagination > aside {
  margin-block-start: var(--spacing-3);
}

layout-spacer {
  padding: 1px;
}

ui-label {
  display: inline;
}

nav-item { /* .navbar-item */
  display: inline-flex;
}

form-footer, form > form-button:last-child {
  display: block;
  margin-top: var(--spacing-5);
  text-align: center;
}

h-captcha {
  display: block;
  margin-top: var(--spacing-5);
}

/*
# Class names to use for these elements:

layout-box = .box
layout-column = .column
layout-columns = .columns
layout-sidebar = .column
nav-inner = .container
nav-menu = .navbar-menu
nav-section = .navbar-brand, .navbar-start, .navbar-end
*/

.sl-theme-dark sl-input::part(base) {
  background: var(--color-darker-blue);
  border: var(--callout-border);
}

.sl-theme-dark sl-tags-input::part(tag) {
  background-color: rgb(var(--sl-color-primary-50));
  border-color: rgb(var(--sl-color-primary-100));
  color: rgb(var(--sl-color-primary-800));
}

.sl-theme-dark sl-tags-input {
  --sl-panel-background-color: var(--sl-color-primary-50);
  --sl-panel-border-color: var(--sl-color-primary-100);
}

body > .sl-toast-stack {
  top: 5rem;
}

sl-alert::part(base) {
  border-radius: var(--radius);
  border-top-width: 1px;
}

sl-icon[library="linea"]::part(svg) {
  overflow: initial;
  fill: currentColor;
}

sl-icon[library="linea"][size="small"] {
  font-size: 85%;
}

new-comment:not(:defined) {
  display: none;
}

bank-comment:not(:defined) {
  display: block;
  margin-block: var(--spacing-3);
}

bank-comment-actions:not(:defined) *[slot] {
  display: none;
}
